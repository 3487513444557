import MainPage from "../pages/MainPage";
import ConcertHallPage from "../pages/ConcertHallPage";
import QuizPage from "../pages/QuizPage";

export default class MenuItems {
    static ACTIVE_MENU_KEY = "active_menuitem";

    static MAIN = "Bemutatkozás";

    static CONCERT_HALL = "Hangversenyterem";

    static QUIZ = "Kvíz";

    static SPONSORS = "Támogatók";

    static CREATORS = "Alkotók";

    static CONTACT = "Kapcsolat";

    static menuItems = {
        [MenuItems.MAIN]: {
            title: MenuItems.MAIN,
            url: "/",
            comp: MainPage
        },
        [MenuItems.CONCERT_HALL]: {
            title: MenuItems.CONCERT_HALL,
            url: "/hangverseny",
            comp: ConcertHallPage
        },
        [MenuItems.QUIZ]: {
            title: MenuItems.QUIZ,
            url: "/kviz",
            comp: QuizPage
        },
        [MenuItems.CREATORS]: {
            title: MenuItems.CREATORS,
            url: "/#creators",
            comp: MainPage
        },
        [MenuItems.SPONSORS]: {
            title: MenuItems.SPONSORS,
            url: "/#sponsors",
            comp: MainPage
        },
        [MenuItems.CONTACT]: {
            title: MenuItems.CONTACT,
            url: "#contact",
            comp: MainPage
        }
    };

    static defaultItem = MenuItems.menuItems[MenuItems.MAIN];

    static searchByUrl = (url: string) => {
        for (const e of Object.values(MenuItems.menuItems)) {
            if (e.url === url)
                return e.title;
        }
        return null;
    }
}
