import React, {useEffect, useState} from 'react';
import "./MainPage.css";

export default function MainPage() {

    return <section className="page">

        <h1 className="mainhead">Bemutatkozás</h1>

        <section className="main intro">
            <img className="bgimg1" src="/img/hatterelem_1.svg"/>
            <h2>tisztelt látogató<span className="normal">!</span></h2>

            <p>A <em>Fermáta – Interaktív hangversenyterem</em> a <a href="http://dunaszimfonikusok.hu/" target="_blank"
                                                            rel="noreferrer">Duna Szimfonikus
                Zenekar</a> és a Pécsi Tudományegyetem közös közönségnevelő projektje, ötletgazdája
                és művészeti koordinátora Igric Balázs, a zenekar trombitaművésze és az egyetem művészeti karának
                doktorandusza.
            </p>

            <p>Célunk, hogy a digitalizáció adta lehetőségekkel a zenei előadást közelebb vigyük a befogadóhoz.
                A szoftver fiataloknak készült az általános iskolai és középiskolai ének-zene tananyaggal összhangban,
                de azoknak a felnőtt zenebarátoknak is szeretettel ajánljuk, akik nyitottak a hagyományos
                közönségszerepből
                történő kilépésre. </p>

            <p>Virtuális hangversenytermünket kreatív funkciókkal láttuk el. Ezek segítségével Ön megismerheti a
                szimfonikus zenekar munkáját, megérthet alapvető zenei folyamatokat és akusztikai jelenségeket,
                illetve bepillanthat a kulisszák mögé. A hagyományos videókról szabadon válthat kisebb hangszeres
                csoportok – zenekari szólamok – felvételeire, a zenehallgatás megszakítása nélkül. A művészek
                tevékenységét is átélheti az egyes szólamkották követésével és a kiemelt hangszerspecifikus hangsávok
                által.</p>

            <p>A zenekari videófelvételeket, szólam- és kottanézeteket feliratos narráció kíséri. A kísérőszövegekkel
                olyan háttérinformációkat szeretnénk megosztani, melyek átadására hagyományos formában, koncert
                közben nem lenne lehetőség.</p>

            <p className="subhead caps">tartalmas időtöltést kívánunk Önnek!</p>

            <p className="subhead">A Fermáta alkotói</p>

            <p><em>A Fermáta – Interaktív hangversenyterem az Innovációs és Technológiai Minisztérium Kooperatív Doktori
                Program doktori hallgatói ösztöndíj programjának a Nemzeti Kutatási, Fejlesztési és Innovációs
                Alapból finanszírozott szakmai támogatásával készült.</em></p>
        </section>

        <section className="main creators-pre">
            <img className="bgimg2" src="/img/hatterelem_2.svg"/>
        </section>

        <h1 id="creators">Alkotók</h1>



        <section className="main creators">

            <section className="collaborator">
                <p>Koncepció:</p>
                <p>Igric Balázs</p>
            </section>

            <section className="collaborator">
                <p>Projektmenedzser:</p>
                <p>Sipos László</p>
            </section>

            <section className="collaborator">
                <p>Vállalati szakértő:</p>
                <p>Szklenár Ferenc</p>
            </section>

            <section className="collaborator">
                <p>Művészeti referens:</p>
                <p>Dr. Farkas István Péter</p>
            </section>

            <section className="collaborator">
                <p>Szoftveres kivitelezés:</p>
                <p>Kövesdán Gábor</p>
            </section>

            <section className="collaborator">
                <p>Arculat:</p>
                <p>Csányi András</p>
            </section>

            <div className="flex-break"/>

            <section className="collaborator">
                <p>Hang:</p>
                <p>Weisz József</p>
            </section>

            <section className="collaborator">
                <p>Kép:</p>
                <p>Demeter László</p>
            </section>

            <section className="collaborator">
                <p>Kottagrafika:</p>
                <p>Szénási László</p>
            </section>

            <section className="collaborator">
                <p>Közreműködők:</p>
                <p>Duna Szimfonikus Zenekar</p>
            </section>

            <section className="collaborator">
                <p>Vezető karmester:</p>
                <p>Horváth Gábor</p>
            </section>

            <section className="collaborator">
                <p>Fuvola szóló:</p>
                <p>Demeter László</p>
            </section>
        </section>

        <h1 id="sponsors">Támogatók</h1>

        <section className="main sponsors-pre">
            <img className="bgimg3" src="/img/hatterelem_3.svg"/>
        </section>

        <section className="main sponsors">
            <img src="/img/tamogatok.svg" alt="Támogatók"/>
        </section>


    </section>
}