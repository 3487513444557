import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";
import MenuItems from "../util/MenuItems";
import "./MainMenu.css";

export default function MainMenu() {
    const [activeItem, setActiveItem] = useState<string | null>(null)
    const [navbarShow, setNavbarShow] = useState<boolean>(false)
    const navigate = useNavigate()
    const location = useLocation()

    const setActiveMenuItem = (item: string) => {
        setActiveItem(item)
        navigate(MenuItems.menuItems[item].url);
    }

    useEffect(() => {
        const item = Object.keys(MenuItems.menuItems).filter(k => location.pathname.startsWith(MenuItems.menuItems[k].url))[0]
        if (item) {
            setActiveItem(item)
        }
    }, [])

    const classForItem = (item: string) =>
        (activeItem === item) ? "nav-item active" : "nav-item"

    const navbarClass = () =>
        (navbarShow) ? "collapse navbar-collapse show" : "collapse navbar-collapse"

    return <>
        <nav className="navbar fixed-top navbar-expand-xl">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            onClick={() => setNavbarShow(!navbarShow)}
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <span className="navbar-logo">
                    <img src="/img/fermata_logo_square.svg" width="40" height="40" className="d-inline-block align-top"
                         alt="Fermáta logó"/>
                    Fermáta
                    </span>
                </div>
                <div className={navbarClass()} id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        {Object.values(MenuItems.menuItems)
                            .map(m =>
                                <li key={m.title} className={classForItem(m.title)}>
                                    <a className="nav-link" href={m.url}
                                       data-bs-toggle="collapse"
                                       data-bs-target="#navbarSupportedContent"
                                       onClick={() => {
                                           setActiveMenuItem(m.title)
                                           setNavbarShow(!navbarShow)
                                       }}>{m.title}</a>
                                </li>
                            )}
                    </ul>
                </div>
            </div>
        </nav>
    </>;
}