import React, {useEffect, useState} from 'react';
import {useLocalStorage} from "../util/useLocalStorage";
import "./QuizPage.css";
import axios from "axios";

type QuizLevel = {
    level: string,
    passingScore: number,
    questionsPerRound: number,
    secondsPerQuestion: number,
    questions: Array<Question>
}

type Question = {
    q: string,
    a: Array<string>
}

type QuestionDetails = {
    q: string,
    a: Array<string>,
    correct: string,
    selected: string | null
}

enum GameState {
    ERROR,
    PENDING_NAME,
    PENDING_GAME,
    ONGOING_GAME,
    FINISHED_GAME
}

export default function QuizPage() {
    const [nameField, setNameField] = useState<string>("");
    const [quizData, setQuizData] = useState<Array<QuizLevel>>([]);
    const [quizQuestions, setQuizQuestions] = useState<Array<QuestionDetails>>();
    const [gameState, setGameState] = useState<GameState>(GameState.PENDING_NAME);
    const [timeRemaining, setTimeRemaining] = useState<number>(0);
    const [timer, setTimer] = useState<NodeJS.Timer>();
    const [error, setError] = useState<any>(null)

    const [name, setName] = useLocalStorage<string | null>("name", null)
    const [level, setLevel] = useLocalStorage<number>("level", 0)
    const [score, setScore] = useLocalStorage<number>("score", 0)

    const [round, setRound] = useState<number>(0);

    const getCorrectAnswers = () => quizQuestions!!.filter(e => e.selected == e.correct).length || 0;

    useEffect(() => {
        const refreshData = async () => {
            try {
                const response = await axios.get(`/quiz/quiz.json`);
                setQuizData(response.data);
                if (name != null) {
                    setGameState(GameState.PENDING_GAME)
                }
            } catch (error) {
                setError(error)
            }
        }
        refreshData()
    }, [])

    const newGame = () => {
        const currentLevel: QuizLevel = quizData[level];
        setQuizQuestions(currentLevel.questions
            .sort(() => 0.5 - Math.random())
            .map(e => {
                return {correct: e.a[0], selected: null, q: e.q, a: e.a.sort(() => 0.5 - Math.random())}
            })
            .slice(0, currentLevel.questionsPerRound));
        setRound(0);
        startTimer(currentLevel.secondsPerQuestion, 0);
    }

    const startTimer = (seconds: number, currentRound: number) => {
        setTimeRemaining(seconds);
        let current = seconds;
        let timer = setInterval(() => {
            if (current == 0) {
                nextRound(currentRound + 1);
                clearInterval(timer);
            } else {
                current--;
                setTimeRemaining(current)
            }
        }, 1000);
        setTimer(timer);
    }
    const nextRound = (roundToJumpTo: number) => {
        clearInterval(timer);
        if ((roundToJumpTo == quizData[level]?.questionsPerRound)) {
            setGameState(GameState.FINISHED_GAME);
        } else {
            startTimer(quizData[level].secondsPerQuestion, roundToJumpTo);
            setRound(roundToJumpTo);
        }
    }

    const tick = () => <span style={{color: "#034c03"}}>✔</span>

    const cross = () => <span style={{color: "#7e0000"}}>✘</span>

    return <section className="page">
        <h1 style={{
            marginBottom: "2em"
        }}>Kvízjáték</h1>
        <section className="main quiz">


            {gameState == GameState.ERROR &&
                <p>Hiba történt a kvízkérdések betöltésekor, kérjük próbálja újra később.</p>
            }

            {gameState == GameState.PENDING_NAME &&
                <>
                    <p>Név beállítása</p>

                    <input type="text" onChange={e => setNameField(e.target.value)} value={nameField}/>

                    <button onClick={e => {
                        setName(nameField);
                        setGameState(GameState.PENDING_GAME);
                    }}>Kezdjük!
                    </button>
                </>
            }

            {gameState == GameState.PENDING_GAME &&
                <>
                    <p>Üdvözlünk, {name}!</p>

                    {level == quizData.length - 1 &&

                        <p>Jelenleg {quizData[level]?.level} szinten játszol. Ez a legmagasabb nehézségi
                            szint. Ezen a szinten
                            véletlenszerűen kapott {quizData[level]?.questionsPerRound} kérdésből&nbsp;
                            {quizData[level]?.passingScore} kérdésre kell helyesen válaszolnod,
                            hogy a szintet teljesítsd. Minden kérdés
                            megválaszolására {quizData[level]?.secondsPerQuestion} másodperc
                            áll rendelkezésedre.
                        </p>
                    }

                    {level < quizData.length - 1 &&

                        <p>Jelenleg {quizData[level]?.level} szinten játszol. Ezen a szinten
                            véletlenszerűen kapott {quizData[level]?.questionsPerRound} kérdésből&nbsp;
                            {quizData[level]?.passingScore} kérdésre kell helyesen válaszolnod,
                            hogy a következő szintre lépje. Minden kérdés
                            megválaszolására {quizData[level]?.secondsPerQuestion} másodperc áll
                            rendelkezésedre.
                        </p>
                    }

                    <button onClick={e => {
                        newGame();
                        setGameState(GameState.ONGOING_GAME);
                    }}>Mehet!
                    </button>

                    <br/>

                    <button onClick={e => {
                        setGameState(GameState.PENDING_NAME);
                        setLevel(0);
                    }}>Állás törlése
                    </button>
                </>
            }

            {gameState == GameState.ONGOING_GAME &&
                <>
                    <p>{round + 1}. kérdés</p>

                    <p>{quizQuestions!![round].q}</p>

                    <p>Hátralévő idő: {timeRemaining}</p>

                    <ul style={{listStyle: "none"}}>{quizQuestions!![round].a.map((e, i) =>
                        <li key={e}>
                            <input type="radio"
                                   id={"quest" + round + "_" + i}
                                   name={"quest" + round}
                                   checked={quizQuestions!![round].selected == e}
                                   onChange={evt => {
                                       const newQuizQuestions = quizQuestions?.map(e => e);
                                       newQuizQuestions!![round].selected = e;
                                       setQuizQuestions(newQuizQuestions)
                                   }}
                                   value={e}/>&nbsp;
                            <label htmlFor={"quest" + round + "_" + i}>{e}</label>
                        </li>)}</ul>

                    {round + 1 >= quizData[level]?.questionsPerRound ||
                        <button
                            disabled={quizQuestions!![round].selected == null}
                            onClick={e => {
                                nextRound(round + 1);
                            }}>Következő
                        </button>
                    }

                    {round + 1 == quizData[level]?.questionsPerRound &&
                        <button
                            disabled={quizQuestions!![round].selected == null}
                            onClick={e => {
                                nextRound(round + 1);
                            }}>Beküld
                        </button>
                    }

                    <br/>
                    <button onClick={e => {
                        setGameState(GameState.PENDING_GAME);
                        clearInterval(timer);
                    }}>Megszakít
                    </button>
                </>
            }

            {gameState == GameState.FINISHED_GAME &&
                <>
                    {getCorrectAnswers() >= quizData[level]?.passingScore && level < quizData.length - 1 &&
                        <>
                            {setLevel(level + 1)}

                            <p>Gratulálunk, {name}! <strong>Teljesítetted a szintet! A jó válaszaid
                                száma: {getCorrectAnswers()}.</strong>
                                A következő körben már a következő nehézségi szinten játszhatsz.</p>

                            <button onClick={e => {
                                setGameState(GameState.PENDING_GAME);
                            }}>Tovább!
                            </button>
                        </>
                    }

                    {getCorrectAnswers() >= quizData[level]?.passingScore && level == quizData.length - 1 &&
                        <>
                            <p>Gratulálunk, {name}! <strong>Teljesítetted a szintet! A jó válaszaid
                                száma: {getCorrectAnswers()}.</strong>
                                Ez volt a legmagasabb nehézségi szint, így nehezebb kérdéseket
                                nem tudunk adni, de a szintet akárhányszor újrajátszhatod.</p>

                            <button onClick={e => {
                                setGameState(GameState.PENDING_GAME);
                            }}>Tovább!
                            </button>
                        </>
                    }

                    {getCorrectAnswers() < quizData[level]?.passingScore &&

                        <>
                            <p>{name}, a jó válaszaid száma: <strong>{getCorrectAnswers()}.</strong> Még gyakorolnod
                                kell, hogy teljesítsd
                                a szintet.</p>

                            <button onClick={e => {
                                setGameState(GameState.PENDING_GAME);
                            }}>Tovább!
                            </button>
                        </>
                    }

                    {getCorrectAnswers() < quizData[level]?.questionsPerRound &&
                        <>
                            <p style={{marginTop: "3em"}}>A helyes válaszok:</p>

                            {quizQuestions?.map(q =>
                                <>
                                    <p>{q.q}</p>
                                    <ul>
                                        {q.a.map((a, i) =>
                                            <li key={i}>{a} {a == q.correct && tick()} {q.selected == a && a != q.correct && cross()}</li>
                                        )}
                                    </ul>
                                </>
                            )}
                        </>
                    }
                </>
            }
        </section>
    </section>;
}