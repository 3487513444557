import React, {useEffect, useRef, useState} from 'react';
import "./ConcertHallPage.css";
import axios from "axios";
import Dropdown, {Option} from 'react-dropdown';
import 'react-dropdown/style.css';
import {useLocalStorage} from "../util/useLocalStorage";

type Quality = {
    label: string,
    minBandwidthMbps: number
}

type Track = {
    title: string,
    videoUrl: string,
    tabUrl: string | undefined,
    caption: string | undefined,
    tabCaption: string | undefined,
    labelIcon: string,
    greyIcon: string,
    iconX: number | undefined,
    iconY: number | undefined
}

type Piece = {
    title: string,
    qualities: Array<Quality>,
    animationVideo: string,
    tracks: Array<Track>
}

enum VideoChangeStrategy {
    SINGLE_VIDEO_ELEMENT,
    DOUBLE_VIDEO_ELEMENT
}

// Modify this to alter how videos are changed
const videoChangeStrategy: VideoChangeStrategy = VideoChangeStrategy.SINGLE_VIDEO_ELEMENT
//const videoChangeStrategy: VideoChangeStrategy = VideoChangeStrategy.DOUBLE_VIDEO_ELEMENT


export default function ConcertHallPage() {
    const [pieces, setPieces] = useState<Array<Piece>>([]);
    const [activePiece, setActivePiece] = useState<Piece | null>(null);
    const [activeTrack, setActiveTrack] = useState<Track | null>(null)
    const [activeQuality, setActiveQuality] = useState<Quality>();
    const [savedQuality, setSavedQuality] = useLocalStorage<string | undefined>("quality", "");
    const [subEnabled, setSubEnabled] = useState<boolean>(false);
    const [bandwidth, setBandwidth] = useState<number>(0);
    const [error, setError] = useState<any>(null)
    const videoRef1 = useRef<HTMLVideoElement>(null);
    const videoRef2 = useRef<HTMLVideoElement>(null);
    const [activeVideoRef, setActiveVideoRef] = useState<number>(1)
    const [initialProps, setInitialProps] = useState<object>();
    const [iconMargin, setIconMargin] = useState(0);
    const [activeVideoWidth, setActiveVideoWidth] = useState(0);
    const [isControlVisible, setControlVisible] = useState<boolean>(false);
    const [lastActivityTimestamp, setLastActivityTimestamp] = useState<Date>(new Date())
    const [tabView, setTabView] = useState<boolean>(false);
    const [isAnimationPlaying, setAnimationPlaying] = useState<boolean>(false);
    let timer: any = null;

    const setPiece = (title: string) => {
        const video = activeVideoRef == 1 ? videoRef1.current : videoRef2.current;
        video?.addEventListener("loadeddata", () => {
            video.currentTime = 0;
        })
        const piece = pieces?.filter(t => t.title === title)[0] || null;
        setActivePiece(piece);
        setActiveQuality(piece?.qualities[0])
        setActiveTrack(piece.tracks[0]);
        setTabView(false);
        resetTime();
        setTrackUrl(piece.tracks[0]!!.videoUrl.replace("{quality}", piece?.qualities[0]?.label!!));
    }

    const setTrack = (title: string) => {
        const track = activePiece?.tracks.filter(t => t.title === title)[0] || null;
        if (activeTrack?.title === title) {
            return;
        }
        setActiveTrack(track);
        setTrackUrl(track!!.videoUrl.replace("{quality}", activeQuality?.label!!));
        setTabView(false);
    }

    const resetTime = () => {
        videoRef1.current!.currentTime = 0;
        videoRef2.current!.currentTime = 0;
    }

    const setTrackUrl = (url: string) => {
        if (videoChangeStrategy == VideoChangeStrategy.SINGLE_VIDEO_ELEMENT) {
            const video = activeVideoRef == 1 ? videoRef1.current! : videoRef2.current!;
            const currentTime = video.currentTime;
            video.setAttribute('src', url);
            video.currentTime = currentTime
            video.play()
        } else {
            setInitialProps({})
            const video = activeVideoRef == 1 ? videoRef1.current! : videoRef2.current!;
            const otherVideo = activeVideoRef == 1 ? videoRef2.current! : videoRef1.current!;
            otherVideo.addEventListener("loadeddata", () => {
                otherVideo.currentTime = video.currentTime
                otherVideo.play().then(() => {
                    setActiveVideoRef(activeVideoRef == 1 ? 2 : 1);
                    handleSizeChanges();

                    setTimeout(() => {
                        video.pause()
                    }, 700);
                })
            }, {once: true})
            otherVideo.setAttribute('src', url);
        }


    }

    const trackToPlay = (tv: boolean) => (tv ? activeTrack?.tabUrl : activeTrack?.videoUrl.replace("{quality}", activeQuality!!.label)) || ""

    const caption = () => !tabView ? activeTrack?.caption : activeTrack?.tabCaption

    const toggleTabView = (val: boolean) => {
        if (videoChangeStrategy == VideoChangeStrategy.DOUBLE_VIDEO_ELEMENT) {
            setInitialProps({});
        }
        setTabView(val);
        setAnimationPlaying(false);
        setTrackUrl(trackToPlay(val));
    }

    const toggleAnimation = (val: boolean) => {
        if (videoChangeStrategy == VideoChangeStrategy.DOUBLE_VIDEO_ELEMENT) {
            setInitialProps({});
        }
        setTabView(false);
        setAnimationPlaying(val)
        setTrackUrl(val ? activePiece?.animationVideo!! : activePiece?.tracks[0].videoUrl!!);
    }

    const toggleSubs = (val: boolean) => {
        if (videoChangeStrategy == VideoChangeStrategy.DOUBLE_VIDEO_ELEMENT) {
            setInitialProps({});
        }
        setSubEnabled(val);
        setTrackUrl(trackToPlay(tabView));
    }

    const qualityOptions = (): Array<Option> =>
        [{label: "Automatikus", value: ""}, ...(activePiece?.qualities!!.map(i => {
            return {label: i.label, value: i.label}
        }) || [])];

    const onQualityChange = (option: Option) => {
        if (option.value == "") {
            setSavedQuality("");
            const qualityToSet = activePiece?.qualities.filter(q => q.minBandwidthMbps <= bandwidth).sort((a, b) => b.minBandwidthMbps - a.minBandwidthMbps)[0];
            setActiveQuality(qualityToSet)
            setTrackUrl(activeTrack!!.videoUrl.replace("{quality}", qualityToSet!!.label!!));
        } else {
            const qualityToSet = activePiece?.qualities.filter(q => q.label == option.label)[0];
            setSavedQuality(qualityToSet!!.label)
            setActiveQuality(qualityToSet!!);
            setTrackUrl(activeTrack!!.videoUrl.replace("{quality}", qualityToSet!!.label!!));

        }
    }

    const getActiveQualityOption = (): Option =>
        (savedQuality == "") ? {label: "Automatikus", value: ""} : {
            label: activeQuality?.label!!,
            value: activeQuality?.label!!
        }

    const calculateBandwidth = async (): Promise<number> => {
        const TESTBYTES = 2560000
        const startTime = new Date().getTime();
        await axios.get(`/bandwidthtest.raw`);
        const endTime = new Date().getTime();
        return (TESTBYTES * 8) / ((endTime - startTime / 1000) / 1000 / 1000)
    }

    const leftControlIcons = (): any => {
        return <div className="control-icons left-control-icons" style={{marginLeft: iconMargin}}>
            {!isAnimationPlaying &&
                <>
                    <img alt="Control icon" src="img/ikonok_szurke/tutti.png" onClick={() => {
                        setTrack(activePiece?.tracks[0].title || "")
                    }}/>

                    <img alt="Control icon" src={`img/${activeTrack?.labelIcon}`} onClick={() => {
                        setTrack(activePiece?.tracks[0].title || "")
                    }}/>
                </>
            }
        </div>
    }

    const rightControlIcons = (): any => {
        const isMainTrackPlaying = activeTrack?.title == activePiece?.tracks[0].title
        const hasTab = activeTrack?.tabUrl
        const hasCaption = activeTrack?.caption
        const hasTabCaption = activeTrack?.tabCaption
        const icons = [];

        if (isMainTrackPlaying) {
            if (!isAnimationPlaying) {
                icons.push({
                    src: "img/ikonok_szurke/animacio.png",
                    onClick: () => {
                        toggleAnimation(true)
                    }
                })
            } else {
                icons.push({
                    src: "img/ikonok_szines_videora/animacio.png",
                    onClick: () => {
                        toggleAnimation(false)
                    }
                })
            }
            if (!tabView) {
                icons.push({
                    src: "img/ikonok_szurke/zenemu.png",
                    onClick: () => {
                        toggleTabView(true)
                    }
                })
            } else {
                icons.push({
                    src: "img/ikonok_szines_videora/zenemu_szines.png"
                })
            }
        } else {
            if (hasTab) {
                if (!tabView) {
                    icons.push({
                        src: "img/ikonok_szurke/kottanezet.png",
                        onClick: () => {
                            toggleTabView(true)
                        }
                    })
                } else {
                    icons.push({
                        src: "img/ikonok_szines_videora/kottanezet_szines.png"
                    })
                }
            }
        }

        if (tabView || isAnimationPlaying) {
            icons.push({
                src: "img/ikonok_szurke/videosav.png",
                onClick: () => {
                    toggleTabView(false)
                }
            })
        } else {
            icons.push({
                src: "img/ikonok_szines_videora/videosav_szines.png"
            })
        }

        if (tabView) {
            if (hasTabCaption) {
                if (subEnabled) {
                    icons.push({
                        src: "img/ikonok_szines_videora/felirat_szines.png",
                        onClick: () => {
                            toggleSubs(false)
                        }
                    })
                } else {
                    icons.push({
                        src: "img/ikonok_szurke/felirat.png",
                        onClick: () => {
                            toggleSubs(true)
                        }
                    })
                }
            }
        } else {
            if (hasCaption && !isAnimationPlaying) {
                if (subEnabled) {
                    icons.push({
                        src: "img/ikonok_szines_videora/felirat_szines.png",
                        onClick: () => {
                            toggleSubs(false)
                        }
                    })
                } else {
                    icons.push({
                        src: "img/ikonok_szurke/felirat.png",
                        onClick: () => {
                            toggleSubs(true)
                        }
                    })
                }
            }
        }


        return <div className="control-icons right-control-icons" style={{marginRight: iconMargin}}>
            {icons.map((e, i) =>
                <img alt="Control icon" key={i} src={e.src} onClick={e.onClick}/>)
            }
        </div>
    }


    const instrumentIcons = (): Array<any> => {
        const icons: Array<any> = [];

        if (tabView || isAnimationPlaying)
            return icons;

        if (activeTrack?.title == activePiece?.tracks[0].title) {
            activePiece?.tracks.filter(t => t.iconX).forEach(t => {
                icons.push({
                    src: `img/${t.greyIcon}`,
                    css: {
                        marginLeft: iconMargin,
                        top: t.iconY + "%",
                        left: activeVideoWidth * (t.iconX || 0) / 100
                    },
                    onClick: () => {
                        setTrack(t.title)
                    }
                })
            })
        }
        return icons;
    }

    useEffect(() => {
        const refreshData = async () => {
            if (bandwidth == 0) {
                const bandwidth = await calculateBandwidth();
                setBandwidth(bandwidth);
            }
            try {
                const response = await axios.get(`/media/tracks.json`);
                console.log(response.data);
                setPieces(response.data);
            } catch (error) {
                setError(error)
            }
        }

        refreshData()
    }, [])

    const setFirstPiece = (title: string) => {
        const pieceToSet: Piece = pieces.filter(p => p.title == title)[0];
        const qualityToSet =
            (savedQuality) ?
                pieceToSet?.qualities.filter(q => q.label == savedQuality)[0] :
                pieceToSet?.qualities.filter(q => q.minBandwidthMbps <= bandwidth).sort((a, b) => b.minBandwidthMbps - a.minBandwidthMbps)[0];
        setActiveQuality(qualityToSet);
        setActivePiece(pieceToSet || null);
        setActiveTrack(pieceToSet?.tracks[0] || null);
        setInitialProps({
            "src": pieceToSet?.tracks[0]!!.videoUrl.replace("{quality}", qualityToSet!!.label)
        })
    }

    const handleSizeChanges = () => {
        const videoWidth = (activeVideoRef == 1 ? videoRef1.current?.clientWidth : videoRef2.current?.clientWidth) || 0;
        setActiveVideoWidth(videoWidth);
        setIconMargin((window.innerWidth - videoWidth) / 2);
    }

    const onActivity = () => {
        setControlVisible(true)
        setLastActivityTimestamp(new Date())
    }

    useEffect(() => {
        window.addEventListener("resize", handleSizeChanges);

        return () => {
            window.removeEventListener("resize", handleSizeChanges);
        };
    }, []);

    useEffect(() => {
        timer = setInterval(() => {
            if (Date.now() - lastActivityTimestamp.getTime() > 3000)
                setControlVisible(false)
        }, 3000)
    }, []);

    const videoAttributes = {
        onResize: handleSizeChanges,
        onLoadedData: onActivity,
        onPlay: onActivity,
        onMouseMove: onActivity,
        onTouchStart: onActivity
    }

    return (<section className="page">

        <section className="video-container">
            {activePiece == null &&
                <section className="piece-selector">
                    <ul>
                        {pieces.map(p =>
                            <li onClick={() => setFirstPiece(p.title)}>
                                <p className="author">{p.title.split(":")[0]}:</p>
                                <p className="title">{p.title.split(":")[1]}</p>
                            </li>)}
                    </ul>

                </section>
            }

            {activePiece && (
                <>
                    <section className="controls">
                        { /*
                        <section className="group">
                            <div className="label">Zenemű:</div>
                            <Dropdown options={pieces?.map(i => i.title)} onChange={i => setPiece(i.value)}
                                      value={activePiece?.title} placeholder="Válasszon egy darabot!"/>
                        </section>

                        <section className="group">
                            <div className="label">Videósáv:</div>
                            <Dropdown options={activePiece?.tracks.map(i => i.title) || []}
                                      onChange={i => setTrack(i.value)}
                                      value={activeTrack?.title} placeholder="Válasszon egy videósávot!"/>
                        </section>
                        */}
                        {/*
                        {tabView ||
                            <section className="group">
                                <div className="label">Felbontás:</div>
                                <Dropdown options={qualityOptions()}
                                          onChange={onQualityChange}
                                          value={getActiveQualityOption()} placeholder="Válasszon felbontást!"/>
                            </section>
                        }
                        */}

                        <section className="bandwidth">
                            {qualityOptions().map(e =>
                                <button onClick={() => onQualityChange(e)}
                                        className={getActiveQualityOption().value == e.value ? "active" : ""}>
                                    {e.label}
                                </button>)}
                        </section>

                        { /*
                        <section className="group">
                            <div className="label">Kottanézet:</div>
                            <Switch
                                onChange={toggleTabView}
                                checked={tabView}
                                className="react-switch"
                            />
                            {caption() &&
                                <>
                                    <span>&nbsp;</span>
                                    <div className="label"> Feliratok:</div>
                                    <Switch
                                        onChange={toggleSubs}
                                        checked={subEnabled}
                                        className="react-switch"
                                    />
                                </>
                            }

                        </section>
                        */}
                    </section>

                    {error &&
                        <section className="error">
                            <p>Sajnos váratlan hiba történt.</p>

                            <p>{error}</p>
                        </section>
                    }


                    <section className="video">
                        <video
                            style={activeVideoRef == 1 ? {"display": "block"} : {"display": "none"}}
                            ref={videoRef1}
                            {...initialProps}
                            {...videoAttributes}
                            id="video"
                            controls
                            autoPlay
                            playsInline>
                            Sajnáljuk, a böngészője nem támogatja videók lejátszását.

                            {subEnabled &&
                                <track label="Kommentár" kind="subtitles" src={caption()} default/>
                            }
                        </video>

                        <video
                            style={activeVideoRef == 1 ? {"display": "none"} : {"display": "block"}}
                            ref={videoRef2}
                            {...videoAttributes}
                            id="video"
                            controls
                            autoPlay
                            playsInline>
                            Sajnáljuk, a böngészője nem támogatja videók lejátszását.

                            {subEnabled &&
                                <track label="Kommentár" kind="subtitles" src={caption()} default/>
                            }

                        </video>

                        {isControlVisible && leftControlIcons()}

                        {isControlVisible && rightControlIcons()}

                        {isControlVisible && instrumentIcons().map((e, i) =>
                            <img alt="Control icon" key={i} className="control-icon" src={e.src} style={e.css}
                                 onClick={e.onClick}/>)}
                    </section>
                </>
            )}
        </section>
    </section>)
}