import React from 'react';
import "./Footer.css"

export default function Footer() {
    return <aside className="copyright" id="contact">
        <section>
            <img className="footer-logo" src="img/fermata_logo_square.svg" alt="Fermáta logó"/>
        </section>

        <section>
            <h1>Kapcsolat</h1>

            <p>Írja meg észrevételeit, kérdéseit!</p>

            <p>Az alábbi címen készséggel állunk rendelkezésére:</p>

            <p><a href="mailto:info@interaktivhangverseny.hu">info@interaktivhangverseny.hu</a></p>
        </section>

        <section>
            <img src="img/up.svg" alt="Fel" onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }}/>
        </section>
    </aside>
}